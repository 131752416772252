//TermsOfService.css
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/pages/companyResources/TermsOfService.jsx

import React, { useEffect, useState } from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
    const [activeSection, setActiveSection] = useState(null);

    // Scroll to top on component mount
    useEffect(() => {
        window.scrollTo(0, 0);

        // Add canonical link to head
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = 'https://www.spearrealestategroup.com/terms';  // Update to the correct URL
        document.head.appendChild(link);

        // Cleanup function to remove the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    const toggleSection = (section) => {
        setActiveSection(activeSection === section ? null : section);
    };

    return (
        <div className="terms-of-service-container">
            <h1>Terms of Service</h1>

            <div className="terms-toc">
                <h2>Table of Contents</h2>
                <ul>
                    <li onClick={() => toggleSection('acceptance')}>Acceptance of Terms</li>
                    <li onClick={() => toggleSection('use')}>Use of the Website</li>
                    <li onClick={() => toggleSection('intellectual')}>Intellectual Property</li>
                    <li onClick={() => toggleSection('liability')}>Limitation of Liability</li>
                    <li onClick={() => toggleSection('changes')}>Changes to the Terms</li>
                    <li onClick={() => toggleSection('contact')}>Contact Us</li>
                </ul>
            </div>

            <div className={`terms-section ${activeSection === 'acceptance' ? 'active' : ''}`}>
                <h2>Acceptance of Terms</h2>
                <p>By accessing or using our website, you agree to be bound by these terms and conditions. Please read them carefully.</p>
            </div>

            <div className={`terms-section ${activeSection === 'use' ? 'active' : ''}`}>
                <h2>Use of the Website</h2>
                <p>You may use our website only for lawful purposes...</p>
            </div>

            <div className={`terms-section ${activeSection === 'intellectual' ? 'active' : ''}`}>
                <h2>Intellectual Property</h2>
                <p>All content on this website, including text, graphics, logos, and images, is the property of Spear Real Estate Group...</p>
            </div>

            <div className={`terms-section ${activeSection === 'liability' ? 'active' : ''}`}>
                <h2>Limitation of Liability</h2>
                <p>Spear Real Estate Group shall not be liable for any damages arising from the use of this website...</p>
            </div>

            <div className={`terms-section ${activeSection === 'changes' ? 'active' : ''}`}>
                <h2>Changes to the Terms</h2>
                <p>We reserve the right to modify these terms at any time...</p>
            </div>

            <div className={`terms-section ${activeSection === 'contact' ? 'active' : ''}`}>
                <h2>Contact Us</h2>
                <p>If you have any questions about these terms, please contact us at <a href="mailto:team@spearrealestate.homes">team@spearrealestate.homes</a>.</p>
            </div>
        </div>
    );
}

export default TermsOfService;
