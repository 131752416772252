// MapContext.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/MapContext.js

import React, { createContext, useState, useContext } from 'react';
import { getCachedPropertyDetail } from '../utils/cache.js';

const MapContext = createContext();

// Validate internal application format [lng, lat]
const validateInternalCoordinates = (coords) => {
  if (!Array.isArray(coords)) {
    console.error('Expected an array of coordinate pairs for internal use, but received:', coords);
    return false;
  }
  
  const invalidCoords = coords.filter(coord =>
    !Array.isArray(coord) || coord.length !== 2 || isNaN(coord[0]) || isNaN(coord[1])
  );

  if (invalidCoords.length > 0) {
    console.warn("Invalid coordinates found:", invalidCoords);
    return false;
  }

  return true;
};

const convertToInternalFormat = (coordStr) => {
  if (typeof coordStr !== 'string') {
    console.error('Expected a string for coordinate input but received:', coordStr);
    return [];
  }

  try {
    return coordStr.split(',').map(coordPair => {
      const [lng, lat] = coordPair.trim().split(' ').map(Number);
      if (isNaN(lng) || isNaN(lat)) {
        console.error('Invalid coordinate values found:', coordPair);
        return [0, 0]; // Placeholder for invalid coordinates
      }
      return [lng, lat];
    });
  } catch (error) {
    console.error('Error parsing coordinates from string:', coordStr, error);
    return [];
  }
};

const getCachedCoordinates = (zpid) => {
  const cachedDetail = getCachedPropertyDetail[zpid];
  if (cachedDetail && cachedDetail.data.latitude && cachedDetail.data.longitude) {
    return {
      latitude: cachedDetail.data.latitude,
      longitude: cachedDetail.data.longitude
    };
  }
  return null;
};

export const MapProvider = ({ children }) => {
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [searchType, setSearchType] = useState("");  // Initialize searchType to "location"
/*   console.log("MapProvider initialized with polygonCoordinates:", polygonCoordinates, "and searchType:", searchType);
 */
  const updatePolygonCoordinates = (coords) => {
   /*  console.log("Received coords for polygon:", coords);  // Log incoming coords */

    if (coords && coords.length > 0) {
      setPolygonCoordinates(coords);
      /* console.log('Updated polygon coordinates in context:', coords); // Log after updating the context */
    }
  
    if (!coords || coords.length === 0) {
      console.warn("No valid polygon coordinates provided, skipping update.");
      return;
    }
  
    let internalCoords = [];
    if (Array.isArray(coords)) {
      internalCoords = coords;
    } else if (typeof coords === 'string') {
      internalCoords = coords.split(',').map(coordPair => {
        const [lng, lat] = coordPair.trim().split(' ').map(Number);
        return [lng, lat];
      });
    }
  
/*     console.log("Internal format of coords:", internalCoords);
 */  
    if (validateInternalCoordinates(internalCoords)) {
      setPolygonCoordinates(internalCoords);
      setSearchType("polygon");
/*       console.log('Polygon search type set. Coordinates:', internalCoords);
 */    } else {
      console.warn('Invalid polygon coordinates, searchType not set to polygon.');
    }
  };
  

  return (
    <MapContext.Provider value={{ polygonCoordinates, updatePolygonCoordinates, searchType, setSearchType, getCachedCoordinates }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
