// LoadingSpinnerTwo.jsx
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/loading/LoadingSpinnerTwo.jsx

import React from 'react';
import './LoadingSpinnerTwo.css';

const LoadingSpinnerTwo = ({}) => (
  <div className="overlay">
    <span 
      className="loader bg-slate-600 bg-opacity-40" 
      style={{ animation: `fillLq ${1500}ms linear infinite alternate` }}
    ></span>
  </div>
);

export default LoadingSpinnerTwo;
