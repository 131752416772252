// urlUtils.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/urlUtils.js

export const generateUrlWithFilters = (basePath, filters) => {
  const searchParams = new URLSearchParams();
  /* console.log("Generating URL with basePath:", basePath); */

  // Clear old parameters related to modal views
  searchParams.delete('zpid');
  searchParams.delete('address');

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  const generatedUrl = `${basePath}?${searchParams.toString()}`;
  /* console.log("Generated URL:", generatedUrl); */
  return generatedUrl;
};

export const updateUrlParameter = (url, param, value) => {
  const urlObj = new URL(url);
  /*   console.log("Updating URL parameter:");
    console.log("URL before update:", url);
    console.log("Parameter to update:", param);
    console.log("New value:", value);
   */
  urlObj.searchParams.set(param, value);
  const updatedUrl = urlObj.toString();
  /*   console.log("Updated URL:", updatedUrl); */
  return updatedUrl;
};

export const updateUrlWithSearchParams = (
  updatedFilters,
  currentSearchQuery,
  currentSearchType,
  polygonCoords,
  zpid,
  defaultFilters
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const finalFilters = { ...defaultFilters, ...updatedFilters };

  // Always remove old modal and location-related parameters
  searchParams.delete('zpid');
  searchParams.delete('address');
  searchParams.delete('location');

  // Add polygon only if searchType is 'polygon' and coordinates are present
  if (currentSearchType === 'polygon' && Array.isArray(polygonCoords) && polygonCoords.length > 0) {
    // Format polygonCoords into a string: "lng lat,lng lat,..."
    const formattedPolygon = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(',');
    searchParams.set("polygon", formattedPolygon);  // Set the formatted string
  } else if (currentSearchType === "address") {
    searchParams.set("address", currentSearchQuery);
    searchParams.delete('page'); // Ensure page is deleted for address searches
  } else if (currentSearchType === "location") {
    searchParams.set("location", currentSearchQuery);
    searchParams.set('page', 1); // Set page to 1 for location searches
  }

  // Add updated filters to the URL using generateFilterParams
  const filterParams = generateFilterParams(finalFilters);
  filterParams.forEach((value, key) => {
    searchParams.set(key, value);
  });

  // Ensure correct filter settings for certain conditions
  if (updatedFilters.isForSaleForeclosure) {
    searchParams.set('isNewConstruction', 'false');
    searchParams.set('saleByAgent', 'false');
    searchParams.set('saleByOwner', 'false');
    searchParams.set('isComingSoon', 'false');
  }

  // Remove otherListings if the status type is not ForSale
  if (finalFilters.status_type === "ForRent") {
    searchParams.delete('otherListings');
    searchParams.delete('saleByAgent');
    searchParams.delete('saleByOwner');
    searchParams.delete('isForSaleForeclosure');
    searchParams.delete('isNewConstruction');
    searchParams.delete('isComingSoon');
    searchParams.delete('isAuction');
  }

  // Limit the page number
  const currentPage = Math.min(Number(searchParams.get('page') || 1), 20);
  searchParams.set('page', currentPage);

  // Include the zpid if available
  if (zpid) {
    searchParams.set('zpid', zpid);
  }

  const newUrl = `/listings?${searchParams.toString()}`;
  return newUrl;
};




export const resetFiltersInUrl = (basePath, currentSearchType, polygonCoords, useQuery) => {
  const searchParams = new URLSearchParams(window.location.search);

  // Preserve only necessary parameters based on the search type
  if (currentSearchType === 'polygon' && polygonCoords) {
    searchParams.set('polygon', polygonCoords);
  } else if (currentSearchType === 'location' && useQuery) {
    searchParams.set('location', useQuery);
  } else {
    // Remove polygon and ensure location is kept only if useQuery exists
    searchParams.delete('polygon');
    if (!useQuery) {
      searchParams.delete('location');
    }
  }

  // Remove all other filter-related parameters
  const filterParams = [
    'minPrice',
    'maxPrice',
    'bedsMin',
    'bedsMax',
    'bathsMin',
    'bathsMax',
    'sqftMin',
    'sqftMax',
    'buildYearMin',
    'buildYearMax',
    'lotSizeMin',
    'lotSizeMax',
    'daysOn',
    'daysOnZillow',
    'status_type',
    'home_type',
    'sort',
    'isOpenHousesOnly',
    'isComingSoon',
    'isPendingUnderContract',
    'isForSaleForeclosure',
    'isPreForeclosure',
    'saleByOwner',
    'saleByAgent',
    'isParkView',
    'isWaterView',
    'isWaterfront',
    'isCityView',
    'isMountainView',
    'isNewConstruction',
    'page',
  ];

  filterParams.forEach(param => {
    searchParams.delete(param);
  });

  // Always set the page to 1 on reset
  searchParams.set('page', 1);

  const newUrl = `${basePath}?${searchParams.toString()}`;
  return newUrl;
};


/**
 * Generates query parameters for filters.
 * @param {Object} filters - The filters object containing various filter parameters.
 * @returns {URLSearchParams} - The URLSearchParams object with filter parameters.
 */
export const generateFilterParams = (filters) => {
  const searchParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  return searchParams;
};
