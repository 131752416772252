
//Login.jsx
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/Login.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { logInWithEmailAndPassword, signInWithGoogle, auth, sendPasswordReset } from '../firebase/Firebase.js';
import { getDoc, setDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import AuthContext from '../../contexts/AuthContext.js';
import { AuthContainer, AuthInput, AuthButton, GoogleButton } from './SharedAuthStyles.js';
import { subheaderClass } from "../propertyDetails/CommonStyles.jsx";
import Swal from 'sweetalert2';
import LoadingSpinner from '../loading/LoadingSpinner.jsx';
import useProgress from '../hooks/useProgress.js';
import { TutorialContext } from '../../contexts/TutorialContext.js';

const db = getFirestore();

function Login({ switchToRegister }) {
    const { setTutorialVisible } = useContext(TutorialContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const loginMessage = location.state?.message;
    const [loginError, setLoginError] = useState("");
    const { progress, loading, startProgress, completeProgress } = useProgress(1500); // Modify this line

    function isValidEmail(email) {
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }

    const handlePostLogin = async (user) => {
        const userDocRef = doc(db, "UserAccounts", user.uid);

        try {
            const userDoc = await getDoc(userDocRef);
            if (!userDoc.exists) {
                await setDoc(userDocRef, {
                    name: user.name,
                    userName: user.userName,
                    email: user.email,
                    photoURL: user.photoURL,
                    joinDate: new Date(),
                    lastLoggedIn: new Date()
                });
            } else {
                await updateDoc(userDocRef, {
                    lastLoggedIn: new Date()
                });
            }

            navigate("/dashboard/ProfileSettings");
        } catch (error) {
            console.error("Error handling post-login:", error);
        }
    };

    const handleEmailPasswordLogin = async () => {
        startProgress();
        setLoginError("");
        try {
            const userCredential = await logInWithEmailAndPassword(email, password);
            if (!userCredential) return;

            localStorage.setItem('isLoggedIn', 'true');
            await handlePostLogin(userCredential.user);
            Swal.fire('Success!', 'Signed in successfully!', 'success');
            navigate("/dashboard/ProfileSettings");
        } catch (error) {
            let errorMessage;
            switch (error.code) {
                case "auth/user-not-found":
                    errorMessage = 'Sorry we could not find an account associated with that email, please try again!';
                    break;
                case "auth/wrong-password":
                case "auth/invalid-email":
                    errorMessage = 'Sorry! Incorrect login or password. Please try again';
                    break;
                case "auth/invalid-login-credentials":
                    errorMessage = 'Sorry! Incorrect login or password. Please try again';
                    break;
                case "auth/user-disabled":
                    errorMessage = 'This account has been disabled. Please contact support.';
                    break;
                case "auth/too-many-requests":
                    errorMessage = 'Too many failed login attempts. Please try again later.';
                    break;
                default:
                    errorMessage = error.message;
            }

            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: errorMessage,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3500,
                customClass: {
                    container: 'custom-swal'
                }
            });
            setLoginError(errorMessage);
        } finally {
            completeProgress();
        }
    };

    const handleGoogleLogin = async () => {
        startProgress();
        setLoginError("");
        try {
            const userCredential = await signInWithGoogle();
            if (!userCredential) {
                throw new Error("No user credential returned");
            }
            localStorage.setItem('isLoggedIn', 'true');
            await handlePostLogin(userCredential.user);
            Swal.fire('Success!', 'Signed in successfully!', 'success');
            navigate("/dashboard/ProfileSettings");
        } catch (error) {
            console.error('Login error:', error);
            let errorMessage;
            switch (error.code) {
                case "auth/popup-closed-by-user":
                    errorMessage = 'Sign-in popup was closed before sign in could be completed.';
                    break;
                case "auth/user-not-found":
                    errorMessage = 'No account for that user could be found!';
                    break;
                case "auth/invalid-auth-token":
                    errorMessage = 'Incorrect credentials, please try again.';
                    break;
                case "auth/user-disabled":
                    errorMessage = 'This account has been disabled. Please contact support.';
                    break;
                case "auth/too-many-requests":
                    errorMessage = 'Too many failed login attempts. Please try again later.';
                    break;
                default:
                    errorMessage = error.message;
            }

            Swal.fire({
                icon: 'error',
                title: 'Oops!',
                text: errorMessage,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                    container: 'custom-swal'
                }
            });
        } finally {
            completeProgress();
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleEmailPasswordLogin();
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const inputEmail = await Swal.fire({
            title: 'Reset Password',
            input: 'email',
            inputPlaceholder: 'Enter your email address',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write an email!';
                }
            }
        });

        if (inputEmail.isConfirmed) {
            if (!isValidEmail(inputEmail.value)) {
                Swal.fire('Error', 'Please enter a valid email address.', 'error');
                return;
            }
            try {
                await sendPasswordReset(inputEmail.value);
                Swal.fire('Email Sent', 'Please check your email for a password reset link.', 'success');
            } catch (error) {
                Swal.fire('Error', error.message, 'error');
            }
        }
    };

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        const tutorialShown = localStorage.getItem('tutorialShown') === 'true';

        if (isLoggedIn && !tutorialShown) {
            setTutorialVisible(true);
            localStorage.setItem('tutorialShown', 'true');
        }
    }, [setTutorialVisible]);

    useEffect(() => {
        if (user) {
            navigate("/dashboard/ProfileSettings");
        }
    }, [user, navigate]);

    const uniqueId = `login-${Date.now()}`;

    return (
        <AuthContainer>
            {loading && <LoadingSpinner progress={progress} />}
            <h1 className="mx-auto font-roboto text-lg pb-3 trackingtext-center text-slate-700 font-bold dark:text-purple-600" style={{ textAlign: 'center', marginTop: '0px' }}>Welcome</h1>
            <form onSubmit={handleFormSubmit}>
                <AuthInput
                    id={`${uniqueId}-email`}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address or Username"
                />
                <AuthInput
                    id={`${uniqueId}-password`}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <AuthButton
                    id={`${uniqueId}-submit-button`}
                    type="submit"
                    className="login-button mx-auto">
                    Log in
                </AuthButton>
                <button
                    onClick={handleResetPassword}
                    className="text-sm text-slate-600 focus:text-slate-400 my-1">
                    Forgot Password?
                </button>
            </form>
            <p className='!font-roboto flex justify-center my-4'>
                Connect with:
            </p>
            <GoogleButton
                id={`${uniqueId}-google-button`}
                className="mx-auto p-0"
                onClick={handleGoogleLogin}>
                <div className="google-icon-wrapper">
                    <img src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg" alt="Google Logo" />
                </div>
                <span className="btn-text mx-auto font-semibold">Sign in with Google</span>
            </GoogleButton>
            <div className="flex justify-center">
                <div className='pt-3'>
                    <span className='font-roboto text-sm text-slate-800'>Don't have an account? </span>
                    <button
                        onClick={switchToRegister}>
                        <span className='text-purple-900 hover:text-purple-800 font-roboto text-sm'>Sign Up</span>
                    </button>
                    <span className='font-roboto text-sm text-slate-800 '> now.</span>
                </div>
            </div>
        </AuthContainer>
    );
}

export default Login;
