


// fetchAdditionalDetails.jsx (PRODUCTION)
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/FetchFunctions/fetchAdditionalDetails.jsx
import axios from "axios";
import Swal from "sweetalert2";
import { setCachedPropertyDetail, getCachedPropertyDetail, normalizeKey } from "../utils/cache.js";
import { normalizeAddress } from "../utils/addressUtils.js"; // Import normalizeAddress function

export const fetchAdditionalDetails = async (zpid) => {
    console.log('fetchAdditionalDetails called with zpid:', zpid);

    // Normalize the zpid
    const normalizedZpid = normalizeKey(zpid);

    if (typeof normalizedZpid !== 'string') {
        console.error('Invalid zpid type:', normalizedZpid);
        throw new Error('ZPID must be a valid string');
    }

    // Step 1: Check if data is cached by zpid
    let cachedDetail = getCachedPropertyDetail(normalizedZpid);
    if (cachedDetail) {
        console.log(`Cache hit for zpid: ${normalizedZpid}`);
        return cachedDetail;
    }

    console.log(`Cache miss for zpid: ${normalizedZpid}, fetching from API`);

    try {
        // Define API URL based on environment
        const apiUrl = process.env.NODE_ENV === 'production'
            ? 'https://www.spearrealestategroup.com/api/propertyDetails'
            : 'http://localhost:3000/api/propertyDetails';

        // Use server for the API call
        const response = await axios.get(`${apiUrl}?zpid=${normalizedZpid}`);

        if (!response.data || !response.data.zpid) {
            throw new Error('Invalid data received');
        }

        console.log(`Successfully fetched details for zpid: ${normalizedZpid}`);

        // Step 3: Cache the response by both zpid and normalized address
        const normalizedAddress = normalizeAddress(response.data.address.streetAddress + ' ' +
            response.data.address.city + ' ' +
            response.data.address.state + ' ' +
            response.data.address.zipcode + ' USA');

        setCachedPropertyDetail(normalizedZpid, response.data);  // Cache by zpid
        setCachedPropertyDetail(normalizedAddress, response.data);  // Cache by normalized address

        return response.data;

    } catch (error) {
        console.error(`Error fetching details for zpid ${normalizedZpid}:`, error);
        Swal.fire('Error!', 'Failed to fetch property details.', 'error');
        return null;
    }
};





/* // fetchAdditionalDetails.jsx
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/FetchFunctions/fetchAdditionalDetails.jsx
// fetchAdditionalDetails.jsx
import axios from "axios";
import Swal from "sweetalert2";
import { setCachedPropertyDetail, getCachedPropertyDetail, normalizeKey } from "../utils/cache.js";
import { normalizeAddress } from "../utils/addressUtils.js";

export const fetchAdditionalDetails = async (zpid) => {
    console.log('fetchAdditionalDetails called with zpid:', zpid);

    // Normalize the zpid
    const normalizedZpid = normalizeKey(zpid);

    if (typeof normalizedZpid !== 'string') {
        console.error('Invalid zpid type:', normalizedZpid);
        throw new Error('ZPID must be a valid string');
    }

    // Step 1: Check if data is cached by zpid
    let cachedDetail = getCachedPropertyDetail(normalizedZpid);
    if (cachedDetail) {
        console.log(`Cache hit for zpid: ${normalizedZpid}. Retrieved from cache:`, cachedDetail);
        
        // Ensure the cached detail contains the zpid
        if (!cachedDetail.zpid) {
            console.error('Cached data is missing zpid:', cachedDetail);
            cachedDetail = null; // Force API fetch if incomplete
        } else {
            return cachedDetail;  // Data from cache
        }
    }
    
    console.log(`Cache miss for zpid: ${normalizedZpid}. Fetching from API...`);

    let attempt = 0;
    const maxAttempts = 3;

    while (attempt < maxAttempts) {
        try {
            const host = "zillow-com1.p.rapidapi.com";
            const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;

            const options = {
                method: "GET",
                url: `https://zillow-com1.p.rapidapi.com/property`,
                params: { zpid: normalizedZpid },
                headers: {
                    "x-rapidapi-key": key,
                    "x-rapidapi-host": host,
                },
            };

            const response = await axios.request(options);

            if (!response.data || !response.data.zpid) {
                throw new Error('Invalid data received from API');
            }

            console.log(`Successfully fetched details from API for zpid: ${normalizedZpid}`);

            // Step 3: Cache the response by both zpid and normalized address
            const normalizedAddress = normalizeAddress(response.data.address.streetAddress + ' ' +
                response.data.address.city + ' ' +
                response.data.address.state + ' ' +
                response.data.address.zipcode + ' USA');

            // Ensure the data is complete before caching
            if (!response.data.zpid || !response.data.address) {
                console.error('API response is missing required fields (zpid or address).');
                throw new Error('Incomplete data received from API');
            }

            // Cache by both zpid and address
            setCachedPropertyDetail(normalizedZpid, response.data);  // Cache by zpid
            setCachedPropertyDetail(normalizedAddress, response.data);  // Cache by normalized address

            return response.data;

        } catch (error) {
            attempt++;

            if (attempt >= maxAttempts) {
                console.error(`Error fetching details for zpid ${normalizedZpid} after ${maxAttempts} attempts:`, error);
                Swal.fire('Error!', 'Failed to fetch property details after multiple attempts.', 'error');
                return null;
            }

            console.warn(`Attempt ${attempt} failed for zpid ${normalizedZpid}. Retrying...`, error);
        }
    }
};
 */