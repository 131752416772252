//Sitemap.jsx
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/pages/companyResources/Sitemap.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineHome, AiOutlinePhone, AiOutlineInfoCircle, AiOutlineCalculator, AiOutlineSearch, AiOutlineUnorderedList, AiOutlineEdit, AiOutlineFileText, TbHomeRibbon } from '../../../assets/svgIcons/svgIcons.js';
import { TextInput } from 'flowbite-react';

const SitemapPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const links = [
    { loc: '/', text: 'Home', changefreq: 'daily', priority: 0.9, icon: <AiOutlineHome className="w-5 h-5" />, ariaLabel: 'Home' },
    { loc: '/contact', text: 'Contact', changefreq: 'monthly', priority: 0.6, icon: <AiOutlinePhone className="w-5 h-5" />, ariaLabel: 'Contact' },
    { loc: '/policy', text: 'Policy', changefreq: 'yearly', priority: 0.5, icon: <AiOutlineInfoCircle className="w-5 h-5" />, ariaLabel: 'Policy' },
    { loc: '/rate-search', text: 'Rate Search', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineSearch className="w-5 h-5" />, ariaLabel: 'Rate Search' },
    { loc: '/mortgage-calculator', text: 'Mortgage Calculator', changefreq: 'monthly', priority: 0.7, icon: <AiOutlineCalculator className="w-5 h-5" />, ariaLabel: 'Mortgage Calculator' },
    { loc: '/buyer-resources', text: 'Buyer Resources', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Buyer Resources' },
    { loc: '/search-lenders', text: 'Search Lenders', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineSearch className="w-5 h-5" />, ariaLabel: 'Search Lenders' },
    { loc: '/agent-finder', text: 'Agent Finder', changefreq: 'monthly', priority: 0.5, icon: <AiOutlineSearch className="w-5 h-5" />, ariaLabel: 'Agent Finder' },
    { loc: '/agent-landing', text: 'Agent Landing', changefreq: 'monthly', priority: 0.5, icon: <AiOutlineEdit className="w-5 h-5" />, ariaLabel: 'Agent Landing' },
    { loc: '/renter-resources', text: 'Renter Resources', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Renter Resources' },
    { loc: '/seller-resources', text: 'Seller Resources', changefreq: 'weekly', priority: 0.7, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Seller Resources' },
    { loc: '/refinance', text: 'Refinance', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineCalculator className="w-5 h-5" />, ariaLabel: 'Refinance' },
    { loc: '/home-value', text: 'Home Value', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineCalculator className="w-5 h-5" />, ariaLabel: 'Home Value' },
    { loc: '/list-with-us', text: 'List With Us', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineEdit className="w-5 h-5" />, ariaLabel: 'List With Us' },
    { loc: '/blog', text: 'Blog', changefreq: 'weekly', priority: 0.9, icon: <AiOutlineFileText className="w-5 h-5" />, ariaLabel: 'Blog' },
    { loc: '/about', text: 'About', changefreq: 'yearly', priority: 0.5, icon: <AiOutlineInfoCircle className="w-5 h-5" />, ariaLabel: 'About' },
    { loc: '/buyer-resources/agent-finder-form', text: 'Agent Finder Form', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineSearch className="w-5 h-5" />, ariaLabel: 'Agent Finder Form' },
    { loc: '/investor-resources/investment-calculator', text: 'Investment Calculator', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineCalculator className="w-5 h-5" />, ariaLabel: 'Investment Calculator' },
    { loc: '/investor-resources', text: 'Investor Resources', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Investor Resources' },
    { loc: '/renter-resources/rental-market-trends', text: 'Rental Market Trends', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Rental Market Trends' },
    { loc: '/schedule', text: 'Schedule', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Schedule' },
    { loc: '/seller-resources/value-estimate-form', text: 'Value Estimate Form', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineCalculator className="w-5 h-5" />, ariaLabel: 'Value Estimate Form' },
    { loc: '/featured-listings', text: 'Featured Listings', changefreq: 'monthly', priority: 0.6, icon: <TbHomeRibbon className="w-5 h-5" />, ariaLabel: 'Featured Listings' },
    { loc: '/dashboard', text: 'Dashboard', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Dashboard' },
    { loc: '/seller-resources/value-estimate-details', text: 'Value Estimate Details', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineCalculator className="w-5 h-5" />, ariaLabel: 'Value Estimate Details' },
    { loc: '/dashboard/:tabName', text: 'Dashboard Tabs', changefreq: 'monthly', priority: 0.6, icon: <AiOutlineUnorderedList className="w-5 h-5" />, ariaLabel: 'Dashboard Tabs' },
    { loc: '/blog/optimizing-your-home-sale-seasonal-insights-for-sellers', text: 'Optimizing Your Home Sale: Seasonal Insights for Sellers', changefreq: 'yearly', priority: 0.9, icon: <AiOutlineFileText className="w-5 h-5" />, ariaLabel: 'Optimizing Your Home Sale: Seasonal Insights for Sellers' },
    { loc: '/blog/how-to-buy-a-house', text: 'How to Buy a House', changefreq: 'yearly', priority: 0.9, icon: <AiOutlineFileText className="w-5 h-5" />, ariaLabel: 'How to Buy a House' },
    { loc: '/blog/what-every-seller-wishes-they-knew', text: 'What Every Seller Wishes They Knew', changefreq: 'yearly', priority: 0.9, icon: <AiOutlineFileText className="w-5 h-5" />, ariaLabel: 'What Every Seller Wishes They Knew' },
    { loc: '/blog/5-essential-tips-for-home-buyers-in-2024', text: '5 Essential Tips for Home Buyers in 2024', changefreq: 'yearly', priority: 0.9, icon: <AiOutlineFileText className="w-5 h-5" />, ariaLabel: '5 Essential Tips for Home Buyers in 2024' },
  ];

  const filteredLinks = links.filter(link => link.text.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="max-w-3xl mx-auto p-5">
      <h1 className="text-center mb-5 text-2xl font-thin">Sitemap</h1>
      <TextInput
        type="text"
        placeholder="Search links..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="border-purple-500 w-full mb-5 p-2 text-lg rounded-xl text-purple-500 hover:scale-105 focus:bg-purple-100 transition-transform"
        aria-label="Search links"
      />
      <ul className="list-none p-0 text-purple-800 grid grid-cols-2 gap-4 mx-auto">
        {filteredLinks.map((link, index) => (
          <li key={index} className="mb-3">
            <Link to={link.loc} className="text-lg text-slate-500 hover:underline flex items-center" aria-label={link.ariaLabel} title={link.text}>
              <span className="mr-2 text-purple-500">{link.icon}</span> {link.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SitemapPage;
