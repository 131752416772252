//Firebase.js
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/firebase/Firebase.js
import { initializeApp } from "firebase/app";
import Swal from 'sweetalert2';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import {
  getFirestore,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  runTransaction,
  serverTimestamp
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import { isSupported, getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAsJh710EITju6cpdVGaVMRXZAeIViQHZU",
  authDomain: "sreg-login.firebaseapp.com",
  projectId: "sreg-login",
  storageBucket: "sreg-login.appspot.com",
  messagingSenderId: "758307286071",
  appId: "1:758307286071:web:184450fdcd348c732e23b2",
  measurementId: "G-JXLHQZJ5YW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = isSupported().then((supported) => {
  if (supported) {
    return getAnalytics(app);
  } else {
    console.warn('Firebase Analytics not supported');
    return null;
  }
});
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();
const googleProvider = new GoogleAuthProvider();

// Add the function to send a verification email
export const sendVerificationEmail = async (email) => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No user signed in");
  }

  try {
    await verifyBeforeUpdateEmail(user, email);
  } catch (error) {
    console.error("Error sending verification email:", error);
    throw error;
  }
};

// Update the email only if it has been verified
export const updateUserEmail = async (newEmail) => {
  const user = auth.currentUser;

  if (!user) {
    throw new Error("No user signed in");
  }

  if (!user.emailVerified) {
    throw new Error("Please verify the new email before changing email.");
  }

  try {
    await user.updateEmail(newEmail);
  } catch (error) {
    console.error("Error updating email:", error);
    throw error;
  }
};

// Function to change the user's password
export const changeUserPassword = async (oldPassword, newPassword) => {
  const user = auth.currentUser;

  if (!user || !user.email) {
    Swal.fire('Error!', 'No user is currently signed in.', 'error');
    return;
  }

  try {
    if (typeof oldPassword !== 'string' || typeof newPassword !== 'string') {
      throw new Error('Passwords must be strings.');
    }

    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    Swal.fire('Success!', 'Your password has been updated.', 'success');
  } catch (error) {
    console.error("Error updating password:", error);

    let errorMessage = 'Failed to update password. Please try again.';

    if (error.code === 'auth/invalid-credential') {
      errorMessage = 'The old password is incorrect. Please try again.';
    } else if (error.code === 'auth/weak-password') {
      errorMessage = 'The new password is too weak. Please choose a stronger password.';
    } else if (error.code === 'auth/too-many-requests') {
      errorMessage = 'Too many failed attempts. Please try again later or contact us for assistance.';
    } else if (error.code === 'auth/requires-recent-login') {
      errorMessage = 'Please log in again and try to update your password.';
    } else {
      errorMessage = 'An unexpected error occurred. Please try again later.';
    }

    Swal.fire('Error!', errorMessage, 'error');
  }
};


// Saved Searches
export const saveSearch = async (userId, searchUrl) => {
  const userRef = doc(db, "UserAccounts", userId);

  try {
    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists()) {
        throw new Error("User document does not exist");
      }

      const newSearch = {
        searchUrl,
        timestamp: new Date().toISOString(),
      };

      transaction.update(userRef, {
        savedSearches: arrayUnion(newSearch)
      });
    });
  } catch (error) {
    console.error("Error saving search:", error);
    throw new Error("Failed to save search. Please try again.");
  }
};

// Saved Homes
export const checkIfHomeIsSaved = async (userId, zpid) => {
  try {
    const userRef = doc(db, "UserAccounts", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const isSaved = userData.savedHomes && userData.savedHomes.includes(zpid);
      return isSaved;
    } else {
      console.error("No such document!");
      return false;
    }
  } catch (error) {
    console.error("Error checking if home is saved:", error);
    throw new Error("Failed to check if home is saved.");
  }
};

// Add Home to Saved Homes
export const addHomeToSavedHomes = async (userId, homeDetails) => {
  const userRef = doc(db, "UserAccounts", userId);
  await updateDoc(userRef, {
    savedHomes: arrayUnion(homeDetails) // homeDetails should be the full home object
  });
};

// Remove Home from Saved Homes
export const removeHomeFromSavedHomes = async (userId, homeDetails) => {
  const userRef = doc(db, "UserAccounts", userId);
  await updateDoc(userRef, {
    savedHomes: arrayRemove(homeDetails) // homeDetails should be the full home object
  });
};

// Recently Viewed Homes
export const saveRecentlyViewedHome = async (userId, listing) => {
  if (!listing || !listing.zpid) {
    console.error("Listing or listing.zpid is undefined.");
    return;
  }

  const userRef = doc(db, 'UserAccounts', userId);

  try {
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      // Handle the case where the user document does not exist
      console.warn("User document does not exist, creating a new one.");
      await setDoc(userRef, {
        recentlyViewedHomes: [listing],
        // Add any other default fields for a new user document
      });
      return;
    }

    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error("Document does not exist!");
      }

      let currentRecentlyViewed = userDoc.data().recentlyViewedHomes || [];
      currentRecentlyViewed = currentRecentlyViewed.filter(item => item.zpid !== listing.zpid);
      currentRecentlyViewed.unshift(listing);
      currentRecentlyViewed = currentRecentlyViewed.slice(0, 10);

      transaction.update(userRef, {
        recentlyViewedHomes: currentRecentlyViewed
      });
    });
  } catch (error) {
    console.error("Transaction failed: ", error);
    throw new Error("Could not update recently viewed homes.");
  }
};

// Google Sign in
export const signInWithGoogle = async () => {
  try {
    const userCredential = await signInWithPopup(auth, googleProvider);
    console.log('Google sign-in successful', userCredential.user);
    localStorage.setItem('isLoggedIn', 'true');
    return userCredential;
  } catch (error) {
    if (error.code === 'auth/popup-blocked') {
      alert('It looks like your browser is blocking popups. Please allow popups for this website and try again.');
    } else if (error.code === 'auth/popup-closed-by-user') {
      console.warn('Popup closed by user');
    } else {
      console.error('Error signing in with Google:', error);
    }
    throw error;
  }
};

// Email Sign in
export const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem('isLoggedIn', 'true');
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// Email Register
export const registerWithEmailAndPassword = async (name, email, password, userName, phoneNumber) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const userData = {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      userName: userName ? String(userName) : '',
      phoneNumber: phoneNumber ? String(phoneNumber) : '',
      isLandlordOrProfessional: false,
      joinDate: serverTimestamp(),
      photoURL: "",
      location: "", 
      website: ""
    };
    await setDoc(doc(db, "UserAccounts", user.uid), userData);
    localStorage.setItem('isLoggedIn', 'true');
    return res;
  } catch (err) {
    console.error("Error in registerWithEmailAndPassword:", err);
    throw err;
  }
};

// Password Reset
export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Failed to send password reset email: ", error);
    throw error;
  }
};

// Logout
export const logout = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem('isLoggedIn');
  } catch (error) {
    console.error("Failed to log out:", error);
    throw error;
  }
};

// Profile Photo Upload and Update User's Profile Picture URL
export const uploadProfilePicture = async (file, userId) => {
  if (!file) {
    throw new Error("No file provided for upload");
  }

  const storageRef = ref(storage, `users/${userId}/Profile_Pictures/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const userRef = doc(db, 'UserAccounts', userId);
          await updateDoc(userRef, { photoURL: downloadURL });
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};

// Update User Details in Firestore
export const updateUserDetails = async (userId, userDetails) => {
  const userRef = doc(db, "UserAccounts", userId);
  try {
    await updateDoc(userRef, userDetails);
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error;
  }
};

// Update UserName 
export const updateUserName = async (userId, newName) => {
  const userRef = doc(db, "UserAccounts", userId);
  try {
    await updateDoc(userRef, {
      userName: newName,
    });
  } catch (error) {
    console.error("Error updating Username in Firestore", error);
    throw error;
  }
};

export {
  auth,
  db,
  storage,
  app,
  doc,
  setDoc,
  getDoc,
};
