//GoogleAuthCallback.jsx
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/GoogleAuthCallback.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithCredential, GoogleAuthProvider, getRedirectResult } from "firebase/auth";
import { auth } from '../firebase/Firebase.js';
import Swal from 'sweetalert2';

const GoogleAuthCallback = () => {
    const navigate = useNavigate();
    const [authenticating, setAuthenticating] = useState(true);

    useEffect(() => {
        const handleGoogleSignIn = async () => {
            try {
                const result = await getRedirectResult(auth);
                if (result) {
                    localStorage.setItem('isLoggedIn', 'true');
                    navigate('/dashboard/ProfileSettings');
                } else {
                    setAuthenticating(false);
                    Swal.fire('Error!', 'There was a problem authenticating with Google. Please try again.', 'error');
                }
            } catch (error) {
                console.error("Error handling Google sign-in redirect", error);
                setAuthenticating(false);
                Swal.fire('Error!', 'There was a problem authenticating with Google. Please try again.', 'error');
            }
        };

        handleGoogleSignIn();
    }, [navigate]);

    return (
        <div>
            {authenticating ? (
                <p>Authenticating...</p>
            ) : null}
        </div>
    );
};

export default GoogleAuthCallback;
