
// AuthModal.jsx
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/AuthModal.jsx
import React, { useState, useEffect } from 'react';
import Login from './Login.jsx';
import Register from './Register.jsx';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 90vw; /* More width for smaller devices */
  max-width: 400px; /* Maximum width remains the same */
  height: 80vh;
  max-height: 80vh; 
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 95vh;
  overflow-y: auto; /* Ensure content is scrollable if it overflows vertically */
  margin: auto; /* This will center the modal vertically */




  @media (min-width: 768px) { /* Adjust the width for devices larger than 768px */
    width: 75vw;
  }
  @media (max-width: 400px) { /* Adjust the width for devices larger than 768px */
  padding: .5rem;
}
`;


const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1rem; /* Added some bottom margin for spacing */
`;

const TabButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => props.$active ? 'bold' : 'normal'};
  border-bottom: ${props => props.$active ? '2px solid #7140DD' : 'none'};
  color: ${props => props.$active ? 'black' : 'grey'};
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem 1rem; /* Increased padding for touch targets */
  font-size: 1rem; /* Adjusted font size */
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    color: black;
  }

  @media (min-width: 768px) { /* For larger devices, use the larger font size */
    font-size: clamp(0.25rem, 4vw, 1.25rem);
  }
`;


function AuthModal({ onClose, activeTab: initialActiveTab }) {
  const [activeTab, setActiveTab] = useState(initialActiveTab || 'login');

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Call onClose prop directly to close the modal
    }
  };

  useEffect(() => {
    const handleEscapeKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose(); // Call onClose prop directly to close the modal
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [onClose]); // Add onClose to the dependency array

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    // Update the URL search parameter
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('auth', tabName);
    window.history.pushState({}, '', newUrl);
  };

  return (
    <Overlay onClick={handleOverlayClick}>

        <ModalContent className='overflow-y-auto mx-auto scroll-smooth' onClick={e => e.stopPropagation()}>
        <TabsContainer>
          <TabButton $active={activeTab === 'login'} onClick={() => handleTabChange('login')}>Login</TabButton>
          <TabButton $active={activeTab === 'register'} onClick={() => handleTabChange('register')}>New Account</TabButton>
        </TabsContainer>

        {activeTab === 'login' && <Login isActive={activeTab === 'login'} onClose={onClose} switchToRegister={() => setActiveTab('register')} />}
        {activeTab === 'register' && <Register isActive={activeTab === 'register'} onClose={onClose} switchToLogin={() => setActiveTab('login')} />}
      </ModalContent>

    </Overlay>
  );
}

export default AuthModal;
