// fetchAdditionalDetailsByAddress.jsx (PRODUCTION)
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/FetchFunctions/fetchAdditionalDetailsByAddress.jsx
import axios from "axios";
import Swal from "sweetalert2";
import { normalizeAddress } from "../utils/addressUtils.js";
import { setCachedPropertyDetail, getCachedPropertyDetail, normalizeKey } from "../utils/cache.js"; // Import cache functions

export const fetchAdditionalDetailsByAddress = async (address) => {
  console.log('fetchAdditionalDetailsByAddress called with:', address);
  if (!address) {
    throw new Error('Address is required to fetch details.');
  }

  const normalizedAddress = normalizeAddress(address);

  // Step 1: Check if the data is already in the cache (by address or zpid)
  let cachedDetail = getCachedPropertyDetail(normalizedAddress);
  if (!cachedDetail) {
    cachedDetail = getCachedPropertyDetail(normalizeKey(address.zpid)); // Use normalizeKey for zpid
  }

  if (cachedDetail) {
    console.log(`Cache hit for address: ${normalizedAddress}`);
    return cachedDetail;  // Return the cached data if it exists
  }

  console.log(`Cache miss for address: ${normalizedAddress}, fetching from API`);

  // Define API URL based on environment
  const apiUrl = process.env.NODE_ENV === 'production'
    ? 'https://www.spearrealestategroup.com/api/propertyDetailsByAddress'
    : 'http://localhost:3000/api/propertyDetailsByAddress';

  // Step 2: API call if cache is not found
  let attempt = 0;
  const maxAttempts = 5;
  const baseDelay = 500; // Initial delay of 500 ms
  const maxDelay = 8000; // Max delay of 8 seconds

  while (attempt < maxAttempts) {
    try {
      await new Promise(resolve => setTimeout(resolve, Math.min(maxDelay, baseDelay * (2 ** attempt))));

      // Using your own server for API call
      const response = await axios.get(`${apiUrl}`, {
        params: { address: normalizedAddress },
        headers: {
          "Content-Type": "application/json",
        },
      });


      // Check for expected response structure
      if (!response.data || !response.data.zpid) {
        console.error('Invalid data structure:', response.data);
        throw new Error('Invalid data received');
      }


      // Step 3: Cache the API response by both address and zpid

      const zpid = normalizeKey(response.data.zpid); // Ensure zpid is normalized

      // Cache the API response by both address and zpid
      setCachedPropertyDetail(zpid, response.data);  // Cache by zpid
      setCachedPropertyDetail(normalizedAddress, response.data);  // Cache by address

      return response.data;
    } catch (error) {
      console.error(`Attempt ${attempt + 1}: Error fetching additional details for address ${address}:`, error);
      attempt++;
    }
  }

  Swal.fire('Error!', 'Error fetching additional details after multiple attempts. Please try again later.', 'error');
  return null;
};




/* 
// fetchAdditionalDetailsByAddress.jsx (TESTING)
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/FetchFunctions/fetchAdditionalDetailsByAddress.jsx
import axios from "axios";
import Swal from "sweetalert2";
import { normalizeAddress } from "../utils/addressUtils.js";
import { setCachedPropertyDetail, getCachedPropertyDetail, normalizeKey } from "../utils/cache.js"; // Import cache functions

export const fetchAdditionalDetailsByAddress = async (address) => {
  console.log('fetchAdditionalDetailsByAddress called with:', address);
  if (!address) {
    throw new Error('Address is required to fetch details.');
  }

  const host = "zillow-com1.p.rapidapi.com";
  const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;
  const normalizedAddress = normalizeAddress(address);

  // Step 1: Check if the data is already in the cache (by address or zpid)
  let cachedDetail = getCachedPropertyDetail(normalizedAddress);
  if (!cachedDetail) {
    cachedDetail = getCachedPropertyDetail(normalizeKey(address.zpid)); // Use normalizeKey for zpid
  }

  if (cachedDetail) {
    console.log(`Cache hit for address: ${normalizedAddress}`);
    return cachedDetail;  // Return the cached data if it exists
  }

  console.log(`Cache miss for address: ${normalizedAddress}, fetching from API`);

    // Step 2: API call if cache is not found
  let attempt = 0;
  const maxAttempts = 5;
  const baseDelay = 500; // Initial delay of 500 ms
  const maxDelay = 8000; // Max delay of 8 seconds

  while (attempt < maxAttempts) {
    try {
      await new Promise(resolve => setTimeout(resolve, Math.min(maxDelay, baseDelay * (2 ** attempt))));
      console.log(`Fetching details from API for address: ${address}, attempt: ${attempt + 1}`);

      const options = {
        method: "GET",
        url: `https://zillow-com1.p.rapidapi.com/property`,
        params: { address: normalizedAddress },
        headers: {
          "x-rapidapi-key": key,
          "x-rapidapi-host": host,
        },
      };

      const response = await axios.request(options);

      console.log(`API response for address ${address}:`, response.data);

      // Check for expected response structure
      if (!response.data || !response.data.zpid || !response.data.address) {
        console.error('Invalid or incomplete data structure:', response.data);
        throw new Error('Invalid data received');
      }

      console.log(`Successfully fetched details for address: ${address}`);

      // Step 3: Cache the API response by both address and zpid
      const zpid = normalizeKey(response.data.zpid); // Ensure zpid is normalized

      // Cache the API response by both address and zpid
      setCachedPropertyDetail(zpid, response.data);  // Cache by zpid
      setCachedPropertyDetail(normalizedAddress, response.data);  // Cache by address

      return response.data;
    } catch (error) {
      console.error(`Attempt ${attempt + 1}: Error fetching additional details for address ${address}:`, error);
      attempt++;
    }
  }

  Swal.fire('Error!', 'Error fetching additional details after multiple attempts. Please try again later.', 'error');
  return null;
};


 */