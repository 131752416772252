
// CommonStyles.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/propertyDetails/CommonStyles.jsx

export const headerClass = "p-1 mb-1 section-header text-center !font-lora !font-bold text-[clamp(0.25rem,4vw,1.25rem)] leading-relaxed text-left text-slate-700 dark:!text-purple-100";

export const subheaderClass = "text-[clamp(0.25rem,4vw,1.15rem)] !font-lora font-semibold whitespace-pre-line leading-none text-slate-600 dark:!text-slate-50 py-1 ml-1 ";
export const newSubheaderClass = "text-[clamp(0.25rem,4vw,1.15rem)] m-1 !font-lora font-semibold whitespace-pre-line leading-none text-slate-600 dark:!text-slate-50 p-1 ml-1 ";

export const subheader2Class = "p-1 ml-1 text-[clamp(0.25rem,4vw,.85rem)] font-lora tracking-wide whitespace-pre-line font-bold leading-none text-slate-600 dark:!text-slate-50 py-2";
export const numericValueClass = "text-[clamp(0.75rem,4vw,1rem)] font-lora tracking-wide whitespace-pre-line font-bold leading-none text-slate-600 dark:!text-slate-50 py-1";
export const addressValuesClass = "p-1 text-[clamp(0.75rem,4vw,1rem)] !font-lora font-medium tracking-wide whitespace-pre-line leading-none text-slate-600 dark:!text-slate-50 py-2";

export const featureClass = "p-1 text-[clamp(0.25rem,4vw,.85rem)] font-bold  !text-slate-600 dark:!text-slate-100";
export const feature2Class = "p-1 pt-4 text-[clamp(0.25rem,4vw,.85rem)] font-bold !text-slate-600 dark:!text-slate-50";
export const labelClass = "p-1 text-[clamp(0.25rem,4vw,.85rem)] font-bold text-slate-700 dark:!text-purple-200";
export const paddedLabelClass = "pb-2";
export const bodyClass = "px-1 text-[clamp(.75rem,4vw,.85rem)] font-light text-slate-500 dark:!text-purple-200";
export const popupHeader = "text-[clamp(0.25rem,4vw,1.15rem)] font-lora font-semibold font-bold leading-none text-slate-600 dark:!text-slate-50 py-1";
export const popupsubHeader = "text-[clamp(0.25rem,4vw,1rem)] font-lora font-semibold pr-1 font-bold leading-none text-slate-600 dark:!text-slate-50 py-2";
export const bodyNoWrapClass = " p-1 text-[clamp(0.25rem,4vw,.85rem)] whitespace-pre leading-none font-light text-slate-500 dark:!text-purple-200";
export const subheaderNoWrapClass = "text-[clamp(0.25rem,4vw,1.25rem)] whitespace-auto !font-bold leading-none text-slate-600 dark:!text-slate-50 py-2 ";


export const profileHeaderClass = "text-center !font-semibold text-[clamp(0.25rem,4vw,1.5rem)] text-slate-700 dark:!text-purple-800";
export const profileSubheaderClass = "text-center text-[clamp(0.25rem,4vw,.85rem)] whitespace-pre-line !font-semibold leading-none text-slate-600 dark:!text-purple-700 py-1";

export const profileNameClass = "text-left pr-2 text-[clamp(0.25rem,4vw,1.15rem)] whitespace-pre-line !font-semibold leading-none text-slate-600 dark:!text-purple-100";
export const profileRollClass = "text-left text-[clamp(0.25rem,4vw,.95rem)] whitespace-pre-line !font-normal leading-none text-slate-500 dark:!text-purple-100";
export const profileDetailsClass = "text-left p-4 text-[.85rem] border-t-0 ml-4 text-slate-600 text-[clamp(0.25rem,4vw,.85rem)] whitespace-pre-line dark:!text-purple-100" ;


export const profileHeader2Class = "!font-semibold h-fit my-2 py-2 text-[clamp(0.25rem,4vw,1.25rem)] text-center self-center align-center text-slate-700 dark:!text-slate-50 flex items-center";
export const profileSubheader2Class = "text-[clamp(0.25rem,4vw,1rem)] mt-[.5rem] whitespace-pre-line !font-bold leading-none text-slate-600 dark:!text-slate-50 py-1";
export const profileBodyClass = "text-[clamp(0.25rem,4vw,.85rem)] font-bold text-slate-500 dark:!text-purple-200";
export const profileBodyClassLight = "text-[clamp(0.25rem,4vw,1rem)] font-semi text-slate-600 dark:!text-purple-200";

export const footerBodyClass = "text-[clamp(0.25rem,4vw,1rem)] font-bold text-slate-400 dark:!text-purple-200";

export const formLabelClass = "text-[clamp(0.25rem,4vw,.85rem)] font-bold text-slate-500 dark:!text-purple-200";

export const chatbotClass = "text-[clamp(0.25rem,4vw,1rem)] font-bold !text-neutral-100 tracking-normal dark:!text-purple-200";
 