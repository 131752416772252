// NavBar.jsx
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/NavBar.jsx

import React, { useState, useContext, useRef, useEffect, useMemo, useCallback, Fragment, lazy, Suspense } from "react";
import { startTransition } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Menu, Transition, Dialog } from '@headlessui/react';
import AuthContext from "../../contexts/AuthContext.js";
import { logout } from '../firebase/Firebase.js';
import "./Navbar.css";
import { DarkThemeToggle, Dropdown } from "flowbite-react";
import LoadingSpinnerTwo from "../loading/LoadingSpinnerTwo.jsx";

import { FaRegTimesCircle, FaUserCircle, FaDollarSign, GoHome, GoShare, TbCopy, TbHomeSearch, TbX, IoChevronDown, IoMenu, CgMenuLeftAlt, RiArrowDropDownLine, MdEmail, MdPhone, MdInfo, MdOutlinePrivacyTip, BsCalendar, CiBookmarkCheck, AllBuyerResources, AllHomesForSale, Foreclosures, FTHB, FindTopAgents, RecentHomeSale, ComingSoon, NewConstruction, OpenHouses, FSBO, AiOutlineCalendar, AiOutlineCalculator, FaChartLine, MdLibraryBooks, FaHome, BiBuildingHouse, FaHouseUser, AiOutlineFileText, FaHandshake, MdOutlineOpenInNew, FaUserTie, FaBookOpen, MdOutlineFormatListBulleted, TbMoneybag, FaRegCalendarAlt, FaCalculator, HiCurrencyDollar, AiOutlineDollarCircle, GiMoneyStack  } from '../../assets/svgIcons/svgIcons.js';



// Lazy load external components
const ProfileMenu = lazy(() => import('./ProfileMenu.jsx'));
const AuthModal = lazy(() => import("../Login/AuthModal"));
const Swal = lazy(() => import('sweetalert2'));

const FacebookShareButton = lazy(() => import('react-share').then(module => ({ default: module.FacebookShareButton })));
const TwitterShareButton = lazy(() => import('react-share').then(module => ({ default: module.TwitterShareButton })));
const WhatsappShareButton = lazy(() => import('react-share').then(module => ({ default: module.WhatsappShareButton })));
const EmailShareButton = lazy(() => import('react-share').then(module => ({ default: module.EmailShareButton })));
const FacebookIcon = lazy(() => import('react-share').then(module => ({ default: module.FacebookIcon })));
const TwitterIcon = lazy(() => import('react-share').then(module => ({ default: module.TwitterIcon })));
const WhatsappIcon = lazy(() => import('react-share').then(module => ({ default: module.WhatsappIcon })));
const EmailIcon = lazy(() => import('react-share').then(module => ({ default: module.EmailIcon })));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Navbar({ onCloseAuthModal, closeauthModal, showAuthModal, setShowAuthModal }) {

  const { isAuthenticated, user } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isUserAuthenticated = useMemo(() => isAuthenticated, [isAuthenticated]);
  const dropdownRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [showShareDropdown, setShowShareDropdown] = useState(false);

  const MemoizedLogo = React.memo(() => (
    <img
      src={process.env.PUBLIC_URL + '/assets/SREG-logo.webp'}
      className="h-[5rem] w-[5rem] max-w-[100px] p-1 max-[900px]:mr-4 mr-8"
      alt="Spear Real Estate Group Logo"
      aria-label="Spear Real Estate Logo"
      fetchpriority="high"
    />
  ));
 
  const toggleDropdown = (itemName) => {
    setOpenDropdown(openDropdown === itemName ? null : itemName);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const navigation = [
    {
      name: 'Contact Us',
      categories: [
        {
          name: (
            <div className="flex items-center gap-2">
              <CiBookmarkCheck className="w-5 h-5 text-purple-500" />
              <span>Contact</span>
            </div>
          ),
          href: '/contact'
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdInfo className="w-5 h-5 text-purple-500" />
              <span>About</span>
            </div>
          ),
          href: '/about'
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdEmail className="w-5 h-5 text-purple-500" />
              <span>Email</span>
            </div>
          ),
          href: 'mailto:team@spearrealestate.homes'
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdPhone className="w-5 h-5 text-purple-500" />
              <span>Call</span>
            </div>
          ),
          href: 'tel:+15037394935'
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <BsCalendar className="w-5 h-5 text-purple-500" />
              <span>Schedule a Meeting</span>
            </div>
          ),
          href: '/schedule'
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdOutlinePrivacyTip className="w-5 h-5 text-purple-500" />
              <span>Privacy Policy</span>
            </div>
          ),
          href: '/policy'
        },
      ],
    },
    {
      name: 'Buy',
      categories: [
        {
          name: (
            <div className="flex items-center gap-2">
              <AllBuyerResources className="w-5 h-5 text-purple-500" />
              <span>All Buyer Resources</span>
            </div>
          ),
          href: '/buyer-resources',
          header: true
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <AllHomesForSale className="w-5 h-5 text-purple-500" />
              <span>All Homes for Sale</span>
            </div>
          ),
          href: '/listings',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <Foreclosures className="w-5 h-5 text-purple-500 fill-transparent stroke-1" />
              <span>Foreclosures</span>
            </div>
          ),
          href: '/listings?page=1&isForSaleForeclosure=true&isNewConstruction=false&saleByAgent=false&saleByOwner=false&isComingSoon=false&status_type=ForSale&location=Portland%2C+OR%2C+USA',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FSBO className="w-5 h-5 text-purple-500" />
              <span>For Sale by Owner</span>
            </div>
          ),
          href: '/listings?location=Portland%2C+OR%2C+USA&page=1&listingsOther=1&saleByAgent=false',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <OpenHouses className="w-5 h-5 text-purple-500" />
              <span>Open Houses</span>
            </div>
          ),
          href: '/listings?location=Eugene%2C+OR%2C+USA&page=1&status_type=ForSale&isOpenHouseOnly=1',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <NewConstruction className="w-5 h-5 text-purple-500" />
              <span>New Construction</span>
            </div>
          ),
          href: '/listings?page=1&buildYearMin=2024&location=Portland%2C+OR%2C+USA',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <ComingSoon className="w-5 h-5 text-purple-500" />
              <span>Just Listed</span>
            </div>
          ),
          href: '/listings?daysOn=3&location=Eugene%2C+OR%2C+USA&page=1',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <RecentHomeSale className="w-5 h-5 text-purple-500" />
              <span>Recent Home Sales</span>
            </div>
          ),
          href: '/listings?&status_type=RecentlySold',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FindTopAgents className="w-5 h-5 text-purple-500" />
              <span>Find Top Agents</span>
            </div>
          ),
          href: '/agent-landing',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FTHB className="w-5 h-5 text-purple-500" />
              <span>First Time Buyer Guide</span>
            </div>
          ),
          href: 'https://prezi.com/i/view/Smr7Mc5R7bmp3L0DQQIb/',
          header: false
        }
      ]
    },
    {
      name: 'Invest',
      categories: [
        {
          name: (
            <div className="flex items-center gap-2">
              <FaDollarSign className="w-5 h-5 text-purple-500" />
              <span>All Investor Resources</span>
            </div>
          ),
          href: '/investor-resources',
          header: true
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaChartLine className="w-5 h-5 text-purple-500" />
              <span>Rental Property Analysis</span>
            </div>
          ),
          href: 'https://rent.report/freerentalreport',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <AiOutlineCalendar className="w-5 h-5 text-purple-500" />
              <span>Short-Term Rentals (coming soon)</span>
            </div>
          ),
          href: '#', // Update this href when the link is available
          header: true
        }
        
        /* 
        { name: 'AirBnB Top Reviewed', header: false },
        { name: 'Newly Listed', header: false },
        { name: 'Search All Airbnb Listing', header: false }, // Get Listings
        { name: 'Find Specific Property', header: false },
        { name: 'Get Airbnb Super Hosts', header: false },
        { name: 'Airbnb Historical Data', header: true },
        { name: 'Market Summary', header: false },
        { name: 'Neighborhood Historical Performance', header: false },
        { name: 'Listing Performance Records', header: false }, //Get Airbnb Historical Performance
        { name: 'Occupancy Rates', header: false }, //Get ... Occupancy Rates
        { name: 'Top Airbnb Cities', header: false },
        { name: 'View Property Types by Area', header: false },
        */
      ],
    },
    {
      name: 'Rent',
      categories: [
        {
          name: (
            <div className="flex items-center gap-2">
              <MdLibraryBooks className="w-5 h-5 text-purple-500" />
              <span>All Renter Resources</span>
            </div>
          ),
          href: '/renter-resources',
          header: true
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaHome className="w-5 h-5 text-purple-500" />
              <span>All Rental Properties</span>
            </div>
          ),
          href: '/listings?status_type=ForRent&location=Eugene%2C+OR%2C+USA',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <BiBuildingHouse className="w-5 h-5 text-purple-500" />
              <span>Apartments for rent</span>
            </div>
          ),
          href: '/listings?status_type=ForRent&home_type=Apartments_Condos_Co-ops&location=Eugene%2C+OR%2C+USA',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaHouseUser className="w-5 h-5 text-purple-500" />
              <span>Houses for rent</span>
            </div>
          ),
          href: '/listings?location=Eugene%2C+OR%2C+USA&page=1&status_type=ForRent&home_type=Houses',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <AiOutlineFileText className="w-5 h-5 text-purple-500" />
              <span>Free Rental Forms</span>
            </div>
          ),
          href: '/contact',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <AiOutlineCalculator className="w-5 h-5 text-purple-500" />
              <span>Affordability Calculator</span>
            </div>
          ),
          href: '/investment-calculators', // Add the appropriate link here
          header: false
        }
      ],
    },
    {
      name: 'Sell',
      categories: [
        {
          name: (
            <div className="flex items-center gap-2">
              <MdLibraryBooks className="w-5 h-5 text-purple-500" />
              <span>All Seller Resources</span>
            </div>
          ),
          href: '/seller-resources',
          header: true
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <TbMoneybag className="w-5 h-5 text-purple-500" />
              <span>Get an Instant Offer</span>
            </div>
          ),
          href: '/list-with-us',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdOutlineFormatListBulleted className="w-5 h-5 text-purple-500" />
              <span>Explore your options</span>
            </div>
          ),
          href: '/seller-resources',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <TbHomeSearch className="w-5 h-5 text-purple-500" />
              <span>See your home value</span>
            </div>
          ),
          href: '/home-value',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaBookOpen className="w-5 h-5 text-purple-500" />
              <span>Sellers guide</span>
            </div>
          ),
          href: '/blog',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FindTopAgents className="w-5 h-5 text-purple-500" />
              <span>Find Top Seller's Agents</span>
            </div>
          ),
          href: '/seller-agent-landing',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdOutlineOpenInNew className="w-5 h-5 text-purple-500" />
              <span>List with us</span>
            </div>
          ),
          href: '/list-with-us',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaHandshake className="w-5 h-5 text-purple-500" />
              <span>Post For Sale by Owner</span>
            </div>
          ),
          href: '/contact',
          header: false
        }
      ]    
    },
    {
      name: 'Financing',
      categories: [

        {
          name: (
            <div className="flex items-center gap-2">
              <TbMoneybag className="w-5 h-5 text-purple-500" />
              <span>Get Financing</span>
            </div>
          ),
          href: '/search-lenders',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <AiOutlineDollarCircle className="w-5 h-5 text-purple-500" />
              <span>Check Mortgage Rates</span>
            </div>
          ),
          href: '/rate-search',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <GiMoneyStack className="w-5 h-5 text-purple-500" />
              <span>Creative Financing</span>
            </div>
          ),
          href: '/contact',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <MdOutlineFormatListBulleted className="w-5 h-5 text-purple-500" />
              <span>Tools & Calculators</span>
            </div>
          ),
          header: true
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaCalculator className="w-5 h-5 text-purple-500" />
              <span>Mortgage calculator</span>
            </div>
          ),
          href: '/mortgage-calculator',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <AiOutlineCalculator className="w-5 h-5 text-purple-500" />
              <span>Refinance Calculator</span>
            </div>
          ),
          href: '/refinance',
          header: false
        },
        {
          name: (
            <div className="flex items-center gap-2">
              <FaRegCalendarAlt className="w-5 h-5 text-purple-500" />
              <span>Affordability Calculator</span>
            </div>
          ),
          href: '/mortgage-calculator',
          header: false
        },
        /* 
        { name: 'Amortization calculator', header: false }, 
        { name: 'Debt-to-Income calculator', header: false },
        { name: 'Finance Resources', header: true },
        { name: 'Lender reviews', header: false },
        { name: 'Mortgage learning center', header: false },
        { name: 'Mortgages app', header: false },
        { name: 'Lender resource center', header: false },
        */
      ],
    },
    /* 
    {
      name: 'Short-Term Rentals (Coming Soon)',
      categories: [
        { name: 'Airbnb Rental Data', header: true },
        { name: 'See Top Reviewed Properties', header: false },
        { name: 'Newly Listed', header: false },
        { name: 'Search All Airbnb Listing', header: false }, // Get Listings
        { name: 'Find Specific Property', header: false },
        { name: 'Get Airbnb Super Hosts', header: false },
        { name: 'Airbnb Historical Data', header: true },
        { name: 'Market Summary', header: false },
        { name: 'Neighborhood Historical Performance', header: false },
        { name: 'Listing Performance Records', header: false }, //Get Airbnb Historical Performance
        { name: 'Occupancy Rates', header: false }, //Get ... Occupancy Rates
        { name: 'Top Airbnb Cities', header: false },
        { name: 'View Property Types by Area', header: false },
      ],
    }
    */
  ];


  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCopyLink = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      Swal.fire({
        title: 'Success!',
        text: 'Link copied to clipboard.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy link: ', err);
      Swal.fire('Error!', 'Failed to copy link.', 'error');
    }
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      Swal.fire({
        title: 'Logged Out!',
        text: 'You have successfully logged out.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
      navigate('/home');
    } catch (error) {
      Swal.fire('Error!', 'Failed to log out.', 'error');
    }
  }, [navigate]);

  const renderCategoryItems = (categories) => {
    return categories.map((category, index) => (
      <Menu.Item key={index}>
        {({ active }) => (
          <Link
            to={category.href || '#'}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 dark:text-gray-300',
              'block px-4 py-2 text-sm'
            )}
            aria-label={category.name}
          >
            {category.icon && <span className="mr-2">{category.icon}</span>}
            {category.name}
          </Link>
        )}
      </Menu.Item>
    ));
  };

  const renderMobileDropdown = (item) => {
    const showDropdown = openDropdown === item.name;

    return showDropdown ? (
      <Menu as={Fragment}>
        <Transition
          show={showDropdown}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Menu.Items>
            <div className="py-1">
              {item.categories.map((category, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <Link
                      to={category.href || '#'}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 dark:text-gray-300',
                        'block px-4 py-2 text-sm'
                      )}
                      aria-label={category.name}
                      onClick={closeMobileMenu}
                    >
                      {category.icon && <span className="mr-2">{category.icon}</span>}
                      {category.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    ) : null;
  };

  const mobileMenuPanelClasses = "pointer-events-auto w-1/2 max-w-sm absolute left-0 top-[1vh] bottom-[1vh] h-full my-auto z-[100]";
  const logoPath = process.env.PUBLIC_URL + '/assets/SREG-logo.webp';

  return (
    <Suspense fallback={<LoadingSpinnerTwo />}>
    <nav
      className="navMenu dark:border-b dark:border-purple-200 bg-white border-b border-gray-200 dark:py-2 dark:bg-slate-800 dark:border-b-0"
      id="navBar"
      aria-label="Main Navigation"
    >
      <div className="navItemsContainer relative flex items-center w-full dark:bg-slate-800 dark:border-b-0">
        <div className="flex items-center justify-start flex-1">
          <ul className="flex space-x-2 items-center flex-1">
            <DarkThemeToggle
              className="darkThemeToggle m-1 rounded-xl ring-0 focus:ring-0 focus:fill-purple-300 hover:fill-purple-300"
              role="switch"
              aria-checked="false"
              aria-label="Toggle dark mode"
              data-testid="dark-theme-toggle"
              tabIndex="0"
            />
            {message && <div className="message">{message}</div>}
            {isUserAuthenticated ? (
              <ProfileMenu setShowProfileMenu={setShowProfileMenu} handleLogout={handleLogout} isUserAuthenticated={isUserAuthenticated} />
            ) : (
              <div className="userlogin">
                <ProfileMenu setShowProfileMenu={setShowProfileMenu} isUserAuthenticated={isUserAuthenticated} />
              </div>
            )}
            <li className="navMenuIcon text-purple-600 dark:text-purple-800 hover:text-purple-500 dark:hover:text-purple-700-container mx-2 dark:hover:text-slate-50 hover:text-slate-600" title="Home">
              <Link to="/home">
                <GoHome className="navMenuIcon text-purple-600 dark:text-purple-800 hover:text-purple-500 dark:hover:text-purple-700 h-auto" />
              </Link>
            </li>
            <li id="navShareIcon" className="relative mx-2 hover:text-slate-600 dark:hover:text-slate-50" title="Share">
              <Dropdown
                label={
                  <GoShare className="navMenuIcon text-purple-600 dark:text-purple-800 hover:text-purple-500 dark:hover:text-purple-700 h-auto cursor-pointer" />
                }
                inline={true}
                arrowIcon={false}
              >
                <div className="flex flex-col gap-2 p-2">
                  <FacebookShareButton url={window.location.href}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton url={window.location.href}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <EmailShareButton url={window.location.href}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <TbCopy id="navCopyIcon" size={32} className="cursor-pointer" onClick={handleCopyLink} aria-label="Copy link to clipboard" />
                </div>
              </Dropdown>
            </li>
            <li
              id="navMenuIconContainer"
              className="navMenuIcon text-purple-600 dark:text-purple-800 hover:text-purple-500 dark:hover:text-purple-700-container mx-2 dark:hover:text-slate-50 hover:text-slate-600 "
              title="Search"
            >
              <Link to="/listings">
                <TbHomeSearch id="navSearchIcon" className="navMenuIcon text-purple-600 dark:text-purple-800 hover:text-purple-500 dark:hover:text-purple-700 h-auto " />
              </Link>
            </li>

            {/* Mobile Menu Button */}
            <button className="hamburger-btn min-[901px]:hidden lg:hidden" onClick={toggleMobileMenu} aria-label="Toggle mobile menu">
              <CgMenuLeftAlt className="hamburger-icon w-5 h-5" />
            </button>

            <div id="navMenuDropdowns" className="navMenuDropdowns !text-xl flex justify-start items-left flex-1 flex-nowrap space-x-0">
              {navigation.map((item) => (
                <Menu as="div" className="z-50 navMenuDrodpownButtons relative inline-block text-left justify-start items-start items-left justify-left" key={item.name}>
                  <Menu.Button className="z-50 inline-flex justify-center w-full border-slate-300 px-2 py-1 bg-white text-xs font-light text-slate-700 hover:rounded-xl hover:text-slate-700 dark:hover:bg-purple-800/50 hover:bg-gray-50 focus:outline-none dark:hover:text-slate-50 hover:text-slate-600 dark:bg-slate-800 dark:text-white dark:rounded-0 dark:shadow-none" aria-haspopup="true" aria-expanded="false">
                    {item.name}
                    <IoChevronDown className="z-50 mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="z-50 origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-400">
                      <div className="z-50 py-1 dark:bg-slate-700">
                        {renderCategoryItems(item.categories)}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ))}
            </div>
          </ul>
        </div>

        {showAuthModal && (
          <div className="modal-background">
            <AuthModal onClose={onCloseAuthModal} />
          </div>
        )}

        {/* Mobile Menu */}
        <Transition.Root show={isMobileMenuOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={closeMobileMenu}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="z-40 fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className={mobileMenuPanelClasses}>
                    <div className="flex h-full flex-col p-4 bg-white rounded-md shadow-xl overflow-y-auto">
                      <div className="flex-1 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-light text-gray-900">Menu</Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                              onClick={closeMobileMenu}
                              aria-label="Close menu"
                            >
                              <TbX className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        {navigation.map((item) => (
                          <div key={item.name} className="mobileNavDrop bg-black-500 py-3 text-left">
                            <button
                              onClick={() => toggleDropdown(item.name)}
                              className="text-lg font-bold !text-slate-900 dark:hover:bg-gray-200 w-full flex ml-2 items-center"
                              aria-expanded={openDropdown === item.name}
                              aria-controls={`dropdown-${item.name}`}
                            >
                              {item.name}
                              <RiArrowDropDownLine className={`${openDropdown === item.name ? 'rotate-180' : ''} h-5 w-5 transition-transform`} aria-hidden="true" />
                            </button>
                            {renderMobileDropdown(item)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <MemoizedLogo />
      </div>
    </nav>
    </Suspense>
  );
}

export default Navbar;
