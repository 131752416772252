
//ViewModeContext.js
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/ViewModeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const ViewModeContext = createContext();

export const ViewModeProvider = ({ children }) => {
  const [viewMode, setViewMode] = useState('map'); // Default to 'map' initially
  const [isManuallySet, setIsManuallySet] = useState(false);

  const handleResize = () => {
    if (!isManuallySet) { // Only auto-set viewMode if not manually set
      if (window.innerWidth < 640) {
        setViewMode('list'); // On smaller screens, set to 'list' view
      } else {
        setViewMode('both'); // For larger screens, default to 'both' view
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isManuallySet]);

  return (
    <ViewModeContext.Provider value={{ viewMode, setViewMode, setIsManuallySet }}>
      {children}
    </ViewModeContext.Provider>
  );
};

export const useViewMode = () => useContext(ViewModeContext);
