
// SharedAuthStyles.js
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/SharedAuthStyles.js
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Container for the authentication forms
export const AuthContainer = styled.div`
  padding: 2rem;
  background-color: #fff; // Matching the light theme
  border-radius: 12px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
  width: 100%;
  height: auto;
  max-width: 400px; 
  margin: auto;
`;

export const AuthInput = styled.input`
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  &:focus {
    border-color: #5D3AB1;
    outline: none;
    box-shadow: 0 0 0 2px rgba(93, 58, 177, 0.2);
  }
`;

// Button styling for login and Google sign-in
export const AuthButton = styled.button`
  width: 100%;
  padding: 10px 0;
  background-color: #5D3AB1; // Purple background
  color: #fff; // White text
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
  &:hover {
    background-color: #4b2c9c; // Darker purple on hover
  }
`;



export const AuthLink = styled(Link)`
text-decoration: none;
color: #5D3AB1;

&:hover {
    text-decoration: underline;
}
`;
// Add these styles at the bottom of your SharedAuthStyles.js

const GoogleBlue = "#FFFFFF";
const ButtonActiveBlue = "#1669F2";


export const GoogleButton = styled.button`

display: flex;
align-items: center;
justify-content: center;

    cursor: pointer;
    max-width: 400px;
    width: 90%;
    height: 6vh;
    min-width: min-content;
    background-color: ${GoogleBlue};
    border-radius: 12px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top: .25rem;
    padding-left: 1rem;
    border: 1px solid #d3d4d5;

    &:hover {
        box-shadow: 0 0 6px #1669F2;
    }

    &:active {
        background: #1669F2;
    }

    .google-icon-wrapper {

        background-color: white;
        border-radius: 12px;

        img {
            width: 1.25rem;
            height: 1.25rem;
            min-width: min-content;

        }
    }

    .btn-text {
        color: #000000;
        font-size: clamp(0.25rem,4vw,.85rem);
        letter-spacing: 0.2px;
        font-family: "Roboto", sans-serif;
    }
`;

