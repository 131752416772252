// utils/addressUtils.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/addressUtils.js

const addressMappings = {
    "st": "street",
    "rd": "road",
    "ave": "avenue",
    "blvd": "boulevard",
    "ln": "lane",
    "dr": "drive",
    "ct": "court",
    "cir": "circle",
    "pl": "place",
    "ter": "terrace",
    "pkwy": "parkway",
    "sq": "square",
    "trl": "trail",
    "hwy": "highway",
    "way": "way",
    "or": "oregon"
};

export const normalizeAddress = (address) => {
    if (!address) return '';

    return address
        .toLowerCase()
        .replace(/,/g, '') // Remove commas
        .replace(/\./g, '') // Remove periods
        .replace(/#/g, '') // Remove hash symbol
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .split(' ')
        .map(word => addressMappings[word] || word) // Expand abbreviations
        .join(' ')
        .trim();
};

export const isAddress = (query) => {
    // Ensure that the query contains digits at the start followed by a street name (more strict detection)
    const addressPattern = /^\d+\s[A-Za-z]+/;
    return addressPattern.test(query);
  };